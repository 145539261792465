






























import Vue from 'vue'
import AlbumList from '@/library/album/AlbumList.vue'
import ArtistList from '@/library/artist/ArtistList.vue'

export default Vue.extend({
  components: {
    AlbumList,
    ArtistList,
  },
  props: {
    id: { type: String, required: true }
  },
  data() {
    return {
      item: null as any,
    }
  },
  watch: {
    id: {
      immediate: true,
      async handler(value: string) {
        this.item = null
        this.item = await this.$api.getArtistDetails(value)
      }
    }
  }
})
