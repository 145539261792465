



















































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      podcast: null as null | any,
    }
  },
  computed: {
    ...mapGetters({
      playingTrackId: 'player/trackId',
      isPlaying: 'player/isPlaying',
    }),
  },
  async created() {
    this.podcast = await this.$api.getPodcast(this.id)
  },
  methods: {
    async click(track: any) {
      if (!track.playable) {
        return
      }
      const tracks = this.podcast.tracks.filter((x: any) => x.playable)
      const index = tracks.findIndex((x: any) => x.id === track.id)
      return this.$store.dispatch('player/playTrackList', {
        index,
        tracks,
      })
    },
  }
})
