








import ErrorBar from './ErrorBar.vue'
import Default from '@/app/layout/Default.vue'
import Fullscreen from '@/app/layout/Fullscreen.vue'

export default {
  components: {
    ErrorBar,
    Default,
    Fullscreen,
  },
  computed: {
    layout(): string {
      return (this as any).$route.meta.layout || 'Default'
    }
  }
}
