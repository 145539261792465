






















import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      items: null as null | any[],
    }
  },
  async created() {
    this.items = await this.$api.getPodcasts()
  },
  methods: {
    async refresh() {
      await this.$api.refreshPodcasts()
      this.items = await this.$api.getPodcasts()
    }
  }
})
