




















































import Vue from 'vue'
import Logo from './Logo.vue'
import PlaylistNav from '@/playlist/PlaylistNav.vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  components: {
    Logo,
    PlaylistNav,
  },
  methods: {
    ...mapActions(['hideMenu']),
  },
})
