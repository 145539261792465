







































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      playlistName: '',
      showModal: false,
    }
  },
  computed: {
    playlists() {
      return this.$store.state.playlists.slice(0, 10)
    },
  },
  methods: {
    createPlaylist() {
      const name = this.playlistName
      this.playlistName = ''
      this.showModal = false
      return this.$store.dispatch('createPlaylist', name)
    },
    onDrop(playlistId: string, event: any) {
      event.preventDefault()
      const trackId = event.dataTransfer.getData('id')
      return this.$store.dispatch('addTrackToPlaylist', { playlistId, trackId })
    },
    onDragover(event: any) {
      event.preventDefault()
    },
  }
})
