



























import Vue from 'vue'
import AlbumList from '@/library/album/AlbumList.vue'
import TrackList from '@/library/TrackList.vue'
import InfiniteList from '@/shared/components/InfiniteList.vue'

export default Vue.extend({
  components: {
    AlbumList,
    TrackList,
    InfiniteList,
  },
  props: {
    id: { type: String, required: true },
    section: { type: String, default: '' },
  },
  methods: {
    loadAlbums(offset: number) {
      return this.$api.getAlbumsByGenre(this.id, 50, offset)
    },
    loadTracks(offset: number) {
      return this.$api.getTracksByGenre(this.id, 50, offset)
    },
  }
})
