






















import Vue from 'vue'
import Logo from './Logo.vue'

export default Vue.extend({
  components: {
    Logo,
  },
  props: {
    visible: { type: Boolean, required: true },
  },
  computed: {
    build: () => 'i.ls(Alone)',
    buildDate: () => '20201230',
    url: () => 'https://github.com/Bulletin-K/'
  },
})
